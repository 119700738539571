import { Box, Tooltip, Typography } from "@material-ui/core";
// import { Button } from "antd";
import React, { useEffect, useState, Suspense, lazy } from "react";
import whatsapp from "../../../assets/svg/whatsapp.svg";
import imageCompression from "browser-image-compression";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
// import RightChatWindowMessages from "./RightChatWindowMessages";
import RightChatWindowTypingPane from "./RightChatWindowTypingPane";
import SearchMessages from "../../searchMessages";
import SendIcon from "@material-ui/icons/Send";

// import Cross from "../../../assets/svg/cross.svg";
import { Modal } from "@mui/material";
import RightChatRoomInfo from "./RightChatRoomInfo";
import two from "../../../assets/images/Frame.png";

import RightChatWindowHeader from "./RightChatWindowHeader";
import { customAvator } from "../../../actions/types";
const RightChatWindowMessages = lazy(() => import("./RightChatWindowMessages"));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 510,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    p: 4
};

const RightChatWindow = (props) => {
    const [searchWindow, setSearchWindow] = useState(false);

    const {
        selectedRow,
        currentUser,
        rooms,
        search,
        setRoomAvatarUrl,getRoomAvatarUrl,setGroupName,setTopic,
        sendMessageCallBack,kickUserCallBack,
        sendTypingCallBack,
        createVideoCall,
        setReadMarker,
        stopAudioCall,
        getNextPage,
        getPreviousPage,
        createAudioCall,
        sendLocationCallBack,
        sendImageCallBack,
        sendVideoCallBack,
        stopVideoCall,
        leaveRoomCallBack,
        fowardMatrixUploadFile,
        forwardLocation,
        cancelVideoCall,
        sendReaction,
        fetchUserDetails,
        inviteUserListCallBack,
        sendAudioCallBack,
        replyMessageCallback,
        replyUploadFileCallback,
        replySendLocationCallback
    } = props;
    //--------------------------------------drag--------------------------------

    // // console.log("isInitialSyncComplete",)
    // alert(isInitialSyncComplete())
    useEffect(() => {
        if (selectedRow) {
            let dropArea = document.getElementById("drop-area");
            //    console.log("drag area", dropArea);
            dropArea.addEventListener(
                "dragenter",
                (e) => {
                    if (e.currentTarget.contains(e.relatedTarget)) {
                        return;
                    }
                    //    console.log("drag enter-->");
                    e.preventDefault();
                    e.stopPropagation();
                    setDragLeave(true);
                    // activeRef.current = true;
                },
                false
            );
            dropArea.addEventListener(
                "dragleave",
                (e) => {
                    if (e.currentTarget.contains(e.relatedTarget)) {
                        return;
                    }
                    //    console.log("drag leave-->");
                    e.preventDefault();
                    e.stopPropagation();
                    setDragLeave(false);

                    // activeRef.current = false;
                    // // console.log(activeRef.current)
                },
                false
            );
            dropArea.addEventListener(
                "dragover",
                (e) => {
                    if (e.currentTarget.contains(e.relatedTarget)) {
                        return;
                    }
                    //    console.log("drag over-->");

                    e.preventDefault();
                    e.stopPropagation();
                    setDragLeave(false);

                    //    console.log("drag single file", val);

                    // setActive(true);
                    // activeRef.current = true;
                },
                false
            );
            dropArea.addEventListener(
                "drop",
                (e, selectedRow) => {
                    //    console.log("drag CHECKING VALUES......", { e, val });
                    if (e.currentTarget.contains(e.relatedTarget)) {
                        return;
                    }
                    setDragLeave(false);

                    let dt = e.dataTransfer;
                    let files = dt.files;
                    //    console.log("drag is dropped dt and files-->", dt, files);
                    [...files].forEach((singleFile) => {
                        context.setOpenWrapper("gallery");
                        context.setUploadButtonShow(true);
                        context.setLoading(true);
                        const optionsCompress = {
                            maxSizeMB: 1,
                            maxWidthOrHeight: 500,
                            useWebWorker: true
                        };
                        imageCompression(singleFile, optionsCompress).then(
                            (compressedImg) => {
                                context.setDropImage(compressedImg);
                                // sendImageCallBack(
                                //     val,
                                //     compressedImg,
                                //     compressedImg
                                // );
                            }
                        );
                        //    console.log("drag val", singleFile);

                        // sendImageCallBack(val,singleFile)
                    });
                    e.preventDefault();
                    e.stopPropagation();
                    // activeRef.current = false;
                },
                false
            );
        }
    }, []);
    // var messages = [];

    const context = useGlobalContext();
    const [forFilter, setFilter] = useState();
    // sendMessageCallBack(local_path, room, currentUser);
    const [arr, setArr] = useState([]);
    const [roomName, setRoomName] = useState();
    const [dragEnter, setDragLeave] = useState(false);

    useEffect(() => {
        setRoomName(context?.getRoom);
        //    console.log("addddd", context?.getRoom);
        setFilter(context?.getRoom);
    }, [context?.getRoom]);
    // const [arr1,setArr1]=useState([])
    const onHandleChange = (event) => {
        // Access input value
        const query = event.target.value;
        // setSearchName(query);
        // Create copy of item list
        var updatedList = [...forFilter];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            // // console.log("filter")
            return (
                item.name
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
            );
        });
        // Trigger render with updated values
        setRoomName(updatedList);

        //  //console.log("588aaaa ", updatedList);
    };
    const handleCheckUser = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        //    console.log("e.target", value);
        if (checked) {
            setArr([...arr, value]);
        } else {
            setArr(arr.filter((e) => e !== value));
        }
    };
    let isForwarded = "isForwarded";
    const handleForwardMessage = () => {
    
        if (context?.forwardMessage?.chatMessage?.msgtype == "m.text") {
            const local_path = {
                body: context?.forwardMessage?.chatMessage?.body,
                msgtype: "m.text"
            };

            context?.getRoom.forEach((obj) => {
                arr.forEach((array1Obj) => {
                    if (obj.name === array1Obj) {
                        // setArr1(oldArray => [...oldArray,obj])
                        sendMessageCallBack(
                            local_path,
                            obj,
                            currentUser,
                            isForwarded
                        );
                    }
                });
            });
        } else if (
            context?.forwardMessage?.chatMessage?.msgtype == "m.image" ||
            context?.forwardMessage?.chatMessage?.msgtype == "m.video" ||
            context?.forwardMessage?.chatMessage?.msgtype == "m.audio" ||
            context?.forwardMessage?.chatMessage?.msgtype == "m.file"
        ) {
            // // console.log("objjeeeeee12",context?.forwardMessage?.chatMessage?.msgtype == "m.image" ||
            // context?.forwardMessage?.chatMessage?.msgtype ==  "m.video");

            let msgtype = context?.forwardMessage?.chatMessage?.msgtype;

            let room = selectedRow;
            let thumbnailFileUrl =
                context?.forwardMessage?.chatMessage?.info?.thumbnail_url;
            let fileUrl = context?.forwardMessage?.chatMessage?.url;
            //    console.log("objjeeeeee", msgtype, room, thumbnailFileUrl, fileUrl);

            context?.getRoom.forEach((obj) => {
                arr.forEach((array1Obj) => {
                    if (obj.name === array1Obj) {
                        //    console.log("getRoomId", obj);
                        // setArr1(oldArray => [...oldArray,obj])  ;
                        fowardMatrixUploadFile(
                            obj?.roomId,
                            fileUrl,
                            thumbnailFileUrl,
                            msgtype
                        );
                    }
                });
            });
        } else if (
            context?.forwardMessage?.chatMessage?.msgtype == "m.location"
        ) {
            // // console.log("objjeeeeee12",context?.forwardMessage?.chatMessage?.msgtype == "m.image" ||
            // context?.forwardMessage?.chatMessage?.msgtype ==  "m.video");
            let msgtype = context?.forwardMessage?.chatMessage?.msgtype;

            let content = context?.forwardMessage?.chatMessage;

            context?.getRoom.forEach((obj) => {
                arr.forEach((array1Obj) => {
                    if (obj.name === array1Obj) {
                        //    console.log("getRoomId", obj);
                        // setArr1(oldArray => [...oldArray,obj])  ;
                        forwardLocation(
                            content,

                            obj?.roomId,

                            msgtype
                        );
                    }
                });
            });
        }
    };
    // alert("660 selectedRow=== "+JSON.stringify(selectedRow))
    // alert("rooms=== "+rooms)
    const [openDrawer, setOpenDrawer] = useState(false);
    let messages = [];
    //    console.log("ALL messagesselectedRow", selectedRow);
    if (
        selectedRow != undefined &&
        rooms &&
        selectedRow &&
        selectedRow?.messages
    ) {
        for (var i = 0; i < rooms.length; i++) {
            if (selectedRow?.roomId == rooms[i]?.roomId) {
                // context?.setAllMessages(rooms[i]?.messages);
                // updateEvent({ messageing: rooms[i].messages });
                // // console.log("messagesarray", rooms[i]?.messages)

                messages = rooms[i]?.messages;

                // messages.newKey="ahamd"
            }
        }
    }
    // const filterArray=messages.filter((val,index)=>{
    // //    console.log("messagesarray12",messages[index])
    //     if (messages[index]?.content?.msgtype=="m.image"&&messages[index-1]?.content?.msgtype == "m.image") {
    //         return val
    //     }
    // })
    // // console.log("messagesarray", filterArray);

    // for (let index = 0; index < messages.length; index++) {
    // //    console.log("messagesarray", messages[index-1]);

    //     // if (messages[index]?.content?.msgtype == "m.image") {
    //     // //    console.log("messagesarray", messages);
    //     // }
    // }
    // if(typeof i !== "undefined" && index >= 0&&(context?.allMessages[index]?.content?.msgtype=="m.image"))
    // {
    //   if( context?.allMessages[index]?.content?.msgtype=="m.image"||context?.allMessages[index-1]?.content?.msgtype=="m.image"&& message?.content?.msgtype=="m.image")
    //   setTheArray(oldArray => [...oldArray, message?.content]);
    // }

    // // console.log("updatedEvent",event.messageing)

    // if (rooms && selectedRow && selectedRow.messages) {
    //     for (var i = 0; i < rooms.length; i++) {
    //         if (selectedRow.roomId == rooms[i].roomId) {
    //             messages = rooms[i].messages;
    //             context?.setAllMessages(rooms[i].messages)

    //             currentRoom = rooms[i];
    //         }
    //     }
    // }
    console.log("selectedRow?.members", selectedRow?.members);

    return !messages ? (
        <div></div>
    ) : (
        <>
            {selectedRow ? (
                <Suspense
                    fallback={
                        <div style={{ color: "black" }}>isLoading...</div>
                    }
                >
                    {/* <Snackbar
        open={context.open}
        //autoHideDuration={6000}useMemo
        // onClose={handleClose}
        message="Note archived"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      // action={action}
      >
        <Alert
        //   onClose={handleClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Video Call Comming!
          <Button onClick={() => { context.setNewVideo(true) }}>Accept</Button>
          <Button style={{ color: "red" }}>Reject</Button>
        </Alert>


      </Snackbar> */}
                    <div
                        //    onClick={()=>context.setCustomModalOpen(false)}
                        id="drop-area"
                        style={{
                            display: context.newClose && "none",
                            position: "relative",
                            width: "100%"
                        }}
                        // className={
                        //     context.newVideo
                        //         ? "col-md-6 text-white p-0"
                        //         : "col-md-12 text-white p-0"
                        // }
                    >
                        <RightChatWindowHeader
                            cancelVideoCall={cancelVideoCall}
                        />

                        <div
                            className="d-flex m-0 box_done"
                            style={
                                {
                                    // position: "relative"
                                }
                            }
                        >
                            {dragEnter ? (
                                <div
                                    style={{
                                        border: "5px  dashed",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "grey"
                                    }}
                                >
                                    <p style={{ fontSize: "xx-large" }}></p>
                                </div>
                            ) : (
                                <div
                                    className="chating-group"
                                    style={{
                                        //display: context.adjustChat && "none",
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",

                                        border: "1px solid #e0e3ef",
                                        height: "100vh"
                                        // backgroundColor: "gold"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "59px",
                                            zIndex: "1",
                                            padding: "10px 16px",
                                            background: "#f0f2f5"
                                            // borderRadius: "10px 16px 0 0px"
                                        }}
                                        className="sec-colse-btn d-flex justify-content-between align-items-center text-end pb-2 pe-2 text-white font-20"
                                    >
                                       
                                            {/* <div className="img-sec me-3">
                                    <img
                                        src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                                        alt="roomLogo"
                                    />
                                </div> */}
                                            <div className="d-flex" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} >
                                            {selectedRow?.avatarUrl?
                                                <div
                                                  onClick={() =>
                                                setOpenDrawer(!openDrawer)
                                            }
                                                style={{
                                                    padding: "0 15px 0 0"
                                                }}
                                                className="img-sec "
                                            >
                                                <img
                                                    style={{
                                                        height: "40px",
                                                        width: "40px"
                                                    }}
                                                    src={selectedRow?.avatarUrl
||                                                        two}
                                                    className="rounded-circle"
                                                    width="150px"
                                                    alt="Avatar"
                                                />
                                                </div>
                                                :

                                          <div   onClick={() =>
                                                setOpenDrawer(!openDrawer)
                                            }> { customAvator(selectedRow?.name,40,15)} </div>    
                                            }
                                                {/* <div className="online"></div> */}
                                                {/* style={{width: 40, height: 40, borderRadius: 40/ 2}}  */}
                                                {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGLAUGhvGs1mg90ntWQRy0zWejCoWb7fw3ZlZvUCEj3OAtoAWfnlVtiHo7wLptXg451-E&usqp=CAU" /> */}
                                            <div
                                                style={{ paddingBottom: "5px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap" }}
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start"
                                                    }}
                                                    className="mb-1 paragraph"
                                                >
                                                    {selectedRow?.name}
                                                </p>
                                                {selectedRow && (
                                                    <div
                                                        style={{
                                                            color: "#202020",
                                                            display: "flex"
                                                        }}
                                                        // className="group_text"
                                                    >
                                                        {Object?.values(
                                                            selectedRow?.members
                                                        ).map((val, index) => (
                                                            <>
                                                            {val?.userId== localStorage.getItem("mxvr_user_id") ? 
                                                             null
                                                            :
                                                            <h6 className="font-14 m-0 ">
                                                                {val?.name}
                                                                {index ===
                                                                Object?.values(
                                                                    selectedRow?.members
                                                                )?.length -
                                                                    1
                                                                    ? ""
                                                                    : ", "}
                                                            </h6>
                                                            }
                                                            </>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        {openDrawer && (
                                            <RightChatRoomInfo
                                                open={openDrawer}
                                                setOpen={setOpenDrawer}
                                                setRoomAvatarUrl={setRoomAvatarUrl}
                                                getRoomAvatarUrl={getRoomAvatarUrl}
                                                setGroupName={setGroupName
                                                }
                                                fetchUserDetails={fetchUserDetails}
                                                roomInfo={selectedRow}
                                                setTopic={setTopic}

                                                inviteUserListCallBack={
                                                    inviteUserListCallBack
                                                }
                                                leaveRoomCallBack={
                                                    leaveRoomCallBack
                                                }
                                                kickUserCallBack={kickUserCallBack}
                                            />
                                        )}
                                        </div>
                                        <div className="d-flex" style={{ position: "relative" }}>
                                            <a
                                                onClick={() =>
                                                    setSearchWindow(true)
                                                }
                                                className="headericonmargin"
                                            >
                                                <i
                                                    style={{
                                                        pointerEvents:
                                                            context.newVideo
                                                                ? "none"
                                                                : "auto"
                                                    }}
                                                    onClick={() => {}}
                                                    className="fa fa-search text-white callicon"
                                                    title=""
                                                    aria-hidden="true"
                                                ></i>
                                            </a>

                                            <Tooltip
                                                arrow
                                                title={
                                                    context.newVideo
                                                        ? "Already in Call"
                                                        : ""
                                                }
                                                placement="top"
                                            >
                                                <a className="headericonmargin">
                                                    <i
                                                        style={{
                                                            pointerEvents:
                                                                context.newVideo
                                                                    ? "none"
                                                                    : "auto"
                                                        }}
                                                        onClick={() => {
                                                            context.setNewVideo(
                                                                true
                                                            );
                                                            context.setVideoCallStart(
                                                                true
                                                            );

                                                            context.setCreateVideo(
                                                                true
                                                            );
                                                            context.setRoomId(
                                                                context.callStart
                                                            );
                                                            context.setAcceptCallerName(
                                                                "kuumba.videocall"
                                                            );
                                                        }}
                                                        className="fa fa-video-camera text-white callicon"
                                                        title="Start video Call"
                                                    ></i>
                                                </a>
                                            </Tooltip>
                                            <Tooltip
                                                arrow
                                                title={
                                                    context.newVideo
                                                        ? "Already in Call"
                                                        : ""
                                                }
                                                placement="top"
                                            >
                                                {/* <a className="bg-secondary rounded-circle-chat headericonmargin"> */}
                                                <a className="headericonmargin">
                                                    <i
                                                        style={{
                                                            pointerEvents:
                                                                context.newVideo
                                                                    ? "none"
                                                                    : "auto"
                                                        }}
                                                        onClick={() => {
                                                            context.setNewVideo(
                                                                true
                                                            );
                                                            context.setVideoCallStart(
                                                                true
                                                            );

                                                            context.setRoomId(
                                                                context.callStart
                                                            );
                                                            context.setAudioOnly(
                                                                true
                                                            );
                                                            context.setCreateAudio(
                                                                true
                                                            );
                                                        }}
                                                        className="fa fa-phone text-white callicon"
                                                        title="Start Audio Call"
                                                    ></i>
                                                </a>
                                            </Tooltip>
                                            {/* <a
                                    href=""
                                    className="bg-secondary rounded-circle-chat headericonmargin"
                                >
                                    <i
                                        className="fa fa-microphone text-white"
                                        title="Start audio"
                                    ></i>
                                </a> */}

                                            <SearchMessages
                                                room={selectedRow}
                                                getNextPage={getNextPage}
                                                getPreviousPage={
                                                    getPreviousPage
                                                }
                                                search={search}
                                                messages={messages}
                                                searchWindow={searchWindow}
                                                setSearchWindow={
                                                    setSearchWindow
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="divider"></div>

                                    <RightChatWindowMessages
                                        room={selectedRow}
                                        sendReaction={sendReaction}
                                        messages={messages}
                                        setReadMarker={setReadMarker}
                                        getNextPage={getNextPage}
                                        getPreviousPage={getPreviousPage}
                                    />

                                    {context.replyMessage && (
                                        <div className="ReplyPreview">
                                            <div
                                                style={{
                                                    color: "#19C39F",
                                                    opacity: "0.6"
                                                }}
                                                className="d-flex justify-content-between"
                                            >
                                                Replying
                                                <i
                                                    className="fa fa-close "
                                                    onClick={() =>
                                                        context.setReplyMessage(
                                                            ""
                                                        )
                                                    }
                                                ></i>
                                            </div>
                                            <div
                                                style={{ overflow: "auto" }}
                                                className="m-0 overflow-string"
                                            >
                                                {
                                                    context.replyMessage
                                                        .chatMessage.body
                                                }
                                            </div>
                                            <p
                                                style={{ opacity: "0.5" }}
                                                className="m-0"
                                            >
                                                {
                                                    context.replyMessage.roomId
                                                        .sender
                                                }
                                            </p>
                                        </div>
                                    )}
                                    <Modal
                                        open={context.modalOpen}
                                        onClose={() => {
                                            context.setModalOpen(false);
                                            setArr([]);
                                        }}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <>
                                            <Box sx={style}>
                                                <div>
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className="mt-auto w-50 fw-bold">
                                                            Forward To:
                                                        </h6>
                                                        <Tooltip title="Sent">
                                                            <div className="d-flex justify-content-between">
                                                                <button
                                                                    onClick={() => {
                                                                        handleForwardMessage();
                                                                        context.setModalOpen(
                                                                            false
                                                                        );
                                                                    }}
                                                                    className="btn btn-secondary"
                                                                    // disabled={
                                                                    //     arr.length > 0 ? false : true
                                                                    // }
                                                                >
                                                                    <SendIcon />
                                                                </button>{" "}
                                                            </div>
                                                        </Tooltip>
                                                    </div>

                                                    <div className="d-flex">
                                                        {arr.map((val, i) => (
                                                            <Typography
                                                                style={{
                                                                    width: "100px",
                                                                    overflow:
                                                                        "hidden",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    textOverflow:
                                                                        "ellipsis"
                                                                }}
                                                                id="modal-modal-title"
                                                                variant="h6"
                                                                component="h2"
                                                            >
                                                                {i > 0 && ","}
                                                                {val}
                                                                {/* { val} */}
                                                            </Typography>
                                                        ))}
                                                    </div>
                                                </div>
                                                <h6 className="mt-auto w-100 fw-bold">
                                                    Message Forward:
                                                </h6>
                                                <div className="">
                                                    <Typography
                                                        id="modal-modal-description"
                                                        sx={{ mt: 2 }}
                                                        style={{
                                                            color: "#19c39f",
                                                            margin: "10px 0 !important"
                                                        }}
                                                    >
                                                        {
                                                            context
                                                                ?.forwardMessage
                                                                ?.chatMessage
                                                                ?.body
                                                        }
                                                    </Typography>
                                                    <div className="search-member">
                                                        <div className="input-group">
                                                            <input
                                                                style={{
                                                                    background:
                                                                        "transparent"
                                                                }}
                                                                className="form-control border"
                                                                type="search"
                                                                placeholder="search..."
                                                                id="example-search-input"
                                                                onChange={
                                                                    onHandleChange
                                                                }
                                                            />
                                                            <span className="input-group-append">
                                                                <button
                                                                    className="btn btn-outline-secondary border-start-0 border-bottom-0 border ms-n5"
                                                                    type="button"
                                                                >
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="chat-members"
                                                    style={{
                                                        height: "55vh",
                                                        overflow: "auto"
                                                    }}
                                                >
                                                    <ul className="list-unstyled">
                                                        {roomName?.map(
                                                            (room, i) => (
                                                                <>
                                                                    <div
                                                                        key={
                                                                            props
                                                                                ?.rooms[
                                                                                i
                                                                            ]
                                                                                ?.roomId
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                            background:
                                                                                "rgb(255 255 255)",
                                                                            marginTop:
                                                                                "5px",
                                                                            marginRight:
                                                                                "5px",
                                                                            borderBottom:
                                                                                "1px solid hsla(0,0%,87%,.34)"
                                                                        }}
                                                                    >
                                                                        <li
                                                                            key={
                                                                                room?.roomId
                                                                            }
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    padding:
                                                                                        "5px"
                                                                                }}
                                                                                className="list-group-item d-flex active "
                                                                            >
                                                                                <input
                                                                                    style={{
                                                                                        transform:
                                                                                            "scale(1.5)",
                                                                                        margin: "0 10px"
                                                                                    }}
                                                                                    // className="form-check-input me-1"
                                                                                    type="checkbox"
                                                                                    key={
                                                                                        room?.roomId
                                                                                    }
                                                                                    value={
                                                                                        room.name
                                                                                    }
                                                                                    name="roominfo"
                                                                                    onChange={
                                                                                        handleCheckUser
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        paddingLeft:
                                                                                            "5px"
                                                                                    }}
                                                                                    className="img-sec me-3"
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "49px",
                                                                                            height: "49px"
                                                                                        }}
                                                                                        src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                                                                        className="rounded-circle"
                                                                                        width="150px"
                                                                                        alt="Avatar"
                                                                                    />
                                                                                </div>
                                                                                <p
                                                                                    className="w-50"
                                                                                    style={{
                                                                                        paddingTop:
                                                                                            "10px",
                                                                                        width: "245px",
                                                                                        overflow:
                                                                                            "hidden",
                                                                                        whiteSpace:
                                                                                            "nowrap",
                                                                                        textOverflow:
                                                                                            "ellipsis",
                                                                                        display:
                                                                                            "flex",
                                                                                        alignContent:
                                                                                            "center",
                                                                                        alignItems:
                                                                                            "center",
                                                                                        fontWeight: 500
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    {
                                                                                        room.name
                                                                                    }
                                                                                </p>

                                                                                {/* <div className="ms-2 me-auto fw-normal">
                                              {room.name}
                                          </div> */}
                                                                            </label>
                                                                        </li>
                                                                    </div>

                                                                    {/* <p>

    {room.name}
</p>  */}
                                                                </>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </Box>
                                        </>
                                    </Modal>

                                    <RightChatWindowTypingPane
                                        room={selectedRow}
                                        currentUser={currentUser}
                                        sendMessageCallBack={
                                            sendMessageCallBack
                                        }
                                        sendTypingCallBack={sendTypingCallBack}
                                        sendLocationCallBack={
                                            sendLocationCallBack
                                        }
                                        sendImageCallBack={sendImageCallBack}
                                        sendVideoCallBack={sendVideoCallBack}
                                        createVideoCall={createVideoCall}
                                        stopAudioCall={stopAudioCall}
                                        createAudioCall={createAudioCall}
                                        stopVideoCall={stopVideoCall}
                                        sendAudioCallBack={sendAudioCallBack}
                                        replyMessageCallback={
                                            replyMessageCallback
                                        }
                                        replyUploadFileCallback={
                                            replyUploadFileCallback
                                        }
                                        replySendLocationCallback={
                                            replySendLocationCallback
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Suspense>
            ) : (
                <>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <img
                            style={{ width: "200px", height: "200px" }}
                            src={whatsapp}
                            alt="img"
                        />

                        <div style={{ marginTop: "17px" }}>
                            <h2 style={{ textAlign: "center", color: "" }}>
                                Kummba App
                            </h2>
                        </div>
                        <div style={{ position: "absolute", bottom: "30px" }}>
                            <p
                                style={{
                                    textAlign: "center",
                                    color: "darkgray",
                                    fontSize: "14px"
                                }}
                            >
                                {" "}
                                <label className="fa fa-lock"></label>{" "}
                                End-to-end encrypted
                            </p>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default React.memo(RightChatWindow);
