import { MenuItem, SwipeableDrawer } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import EasyCrop from "./easySlider";
import placeholder2 from "../../assets/images/Frame.png";

import { Modal, Box, Typography } from "@mui/material";
import viewPhoto from "./viewPhoto";
import { PhotoProvider, PhotoView } from "react-photo-view";
import img from "../../assets/images/6.png";
import PopoverReuse from "../desktop/DesktopComponents/ReusableComponnet/Popover";
import ModalReuse from "../desktop/DesktopComponents/ReusableComponnet/ModalReuse";
import { useDispatch } from "react-redux";
import { selectRoom } from "../../actions/desktop";
import InviteUser from "../desktop/DesktopComponents/InviteUser";
import { customAvator } from "../../actions/types";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 510,
    height: 600,
    bgcolor: "silver",
    boxShadow: 24,
    p: 4
};
const styleCamera = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 510,
    height: 600,
    bgcolor: "white",
    boxShadow: 24
};
function ProfileEdit(props) {
    const {
        opens,
        setOpen,
        userName,
        setAvatarUrl,
        getAvatarUrl,
        inviteUserCallBack,
        inviteUserListCallBack,
        header,
        roomInfo,
        kickUserCallBack,
        leaveRoomCallBack,
        setGroupName,
        setTopic
    } = props;
    const hiddenFileInput = React.useRef(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [image, setImage] = useState("");
    const [imageCrop, setImageCrop] = useState();
    const [groupName, setGroupNames] = useState("");
    const [topicName, setTopicName] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const [viewProfileSection, setViewProfileSection] = useState("");
    const webcamRef = React.useRef(null);
    const [openInvite, setOpenInvite] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const LeaveRoom = () => {
        // console.log("ABC_ABC_LEAVE_CLICKED");
        let roomId = roomInfo?.roomId;
        leaveRoomCallBack(roomId);

        dispatch(selectRoom(""));
    };

    const KickUser = (member) => {
        // console.log("ABC_ABC_KICK_USER", member);
        kickUserCallBack(roomInfo, member, "");
    };
    const handleSave = () => {
        setGroupName(groupName, roomInfo?.roomId);
        setEditMode(false);
        setTopic(topicName, roomInfo?.roomId);
    };

    const videoConstraints = {
        width: 220,
        height: 200,
        facingMode: "user"
    };
    console.log("roomInfo", roomInfo);
    async function handleAvatarUrl(userId) {
        // const avatarUrl = await getAvatarUrl(userId);
        let id = localStorage.getItem("mxvr_user_id");
        if (header === "Profile Info" && !roomInfo) {
            setImage(await getAvatarUrl(id));
            console.log("profileInfo", await getAvatarUrl(id));
        } else {
            if (roomInfo) {
                setTopicName(roomInfo?.topic);
                setGroupNames(roomInfo?.name);

                // setImage( await getAvatarUrl(roomInfo?.roomId));
                // console.log("roomInfo", await getAvatarUrl(roomInfo?.roomId));
            }
        }
    }
    useEffect(() => {
        handleAvatarUrl();
    }, [header]);

    const handleSubmitImage = (e) => {
        setModalOpen(true);
        const file = e.target.files[0];
        // console.log("image profile",file)
        // setImage(URL.createObjectURL(file))
        setViewProfileSection("upload-photo");
        setImageCrop(URL.createObjectURL(file));
    };
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        fetch(imageSrc)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], "img.jpeg");
                setImageCrop(URL.createObjectURL(file));
                setViewProfileSection("upload-photo");
                setModalOpen(true);

                console.log("imageREsourcse", file);
            });
        // setImage(imageSrc);
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const showProfileClick = (val) => {
        console.log("photo-view", val);
        switch (val) {
            case "view-photo":
                // setViewProfileSection(val)
                // document.getElementById("view").click()

                break;
            case "take-photo":
                setViewProfileSection("take-photo");
                setModalOpen(true);

                break;
            case "View-photo":
                break;
            case "upload-photo":
                break;
            case "remove-photo":
                break;
            default:
                break;
        }
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };
    return (
        <div>
            <SwipeableDrawer
                anchor={"right"}
                open={opens}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                style={{ zIndex: 1, width: "426px" }}
            >
                <div className="groupInfo_main">
                    <div className="gim_header_top">
                        <span
                            className="fa fa-close close_icon"
                            onClick={() => setOpen(false)}
                        />
                        <span className="ht_title"> {header} </span>
                    </div>

                    <div className="gim_img_cont">
                        <div onClick={handleClick} className="gim_ic_imgDiv">
                            {roomInfo?.avatarUrl ? (
                                <img
                                    className="grp_img"
                                    src={roomInfo?.avatarUrl}
                                    alt="image"
                                    // src= {grp}
                                />
                            ) : (
                                <div>
                                    {customAvator(roomInfo?.name, 100, 50)}
                                </div>
                            )}
                            <div className="change_img">
                                <span className="fa fa-solid fa-camera iu_icon">
                                    {" "}
                                </span>
                                <span className="ci_text">
                                    {" "}
                                    Change group icon{" "}
                                </span>
                            </div>
                        </div>

                        {header === "Profile Info" ? (
                            <div className="grp_details">
                                <span className="grp_name"> {userName} </span>
                            </div>
                        ) : (
                            <>
                                <div className="grp_details">
                                    {!editMode ? (
                                        <span className="grp_name">
                                            {" "}
                                            {roomInfo?.name}{" "}
                                            <i
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    setEditMode(true)
                                                }
                                                className="fa fa-pencil"
                                            ></i>
                                        </span>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    borderBottom:
                                                        "2px solid #2abfd4",
                                                    marginTop: "10px",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                <input
                                                    style={{
                                                        border: "none",
                                                        background:
                                                            "transparent",
                                                        outline: "none"
                                                    }}
                                                    type="text"
                                                    value={groupName}
                                                    onChange={(e) =>
                                                        setGroupNames(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "10px",
                                                    borderBottom:
                                                        "2px solid #2abfd4",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                <input
                                                    style={{
                                                        border: "none",
                                                        background:
                                                            "transparent",
                                                        outline: "none"
                                                    }}
                                                    type="text"
                                                    placeholder="Topic"
                                                    value={topicName}
                                                    onChange={(e) =>
                                                        setTopicName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <button
                                                style={{ marginTop: "10px" }}
                                                onClick={handleSave}
                                            >
                                                Save
                                            </button>
                                        </>
                                    )}
                                    <span className="grp_members_count">
                                        {" "}
                                        {`Group - ${
                                            Object.keys(roomInfo?.members)
                                                .length
                                        } participants`}{" "}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                    <PopoverReuse
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        cssClass={"topbar-profile"}
                    >
                        <MenuItem>
                            <PhotoProvider>
                                <div>
                                    <PhotoView src={image}>
                                        <p
                                            // onClick={() => {
                                            //     // showProfileClick("view-photo");
                                            //     handleClose();
                                            // }}
                                            className="paragraph-action"
                                        >
                                            {" "}
                                            View Photo
                                        </p>
                                    </PhotoView>
                                </div>
                            </PhotoProvider>
                        </MenuItem>
                        <MenuItem>
                            <p
                                onClick={() => {
                                    showProfileClick("take-photo");
                                    handleClose();
                                }}
                                className="paragraph-action"
                            >
                                {" "}
                                Take Photo
                            </p>
                        </MenuItem>
                        <MenuItem>
                            <p
                                onClick={() => {
                                    hiddenFileInput.current.click();
                                    handleClose();
                                }}
                                className="paragraph-action"
                            >
                                {" "}
                                Upload Photo
                            </p>
                        </MenuItem>
                        <MenuItem>
                            <p className="paragraph-action"> Remove Photo</p>
                        </MenuItem>
                    </PopoverReuse>
                    <div>
                        <input
                            id="abc"
                            type="file"
                            name="myImage"
                            ref={hiddenFileInput}
                            accept="image/*"
                            onChange={handleSubmitImage}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div
                        style={{ marginTop: "15px" }}
                        className="other_func_container"
                    >
                        <div className="enc_div">
                            <div className="enc_icon_div">
                                <span className="fa fa-solid fa-lock enc_icon"></span>
                            </div>
                            <div className="enc_desc">
                                <span className="enc_d_title">
                                    {" "}
                                    Encryption{" "}
                                </span>
                                <span className="enc_d_detail">
                                    {" "}
                                    Messages are end-to-end encrypted. Click to
                                    learn more.{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                    {header === "Group Info" && (
                        <>
                            <div className="gim_membersList_cont">
                                <div className="header_div">
                                    <span className="member_count">
                                        {" "}
                                        {`${
                                            Object.keys(roomInfo?.members)
                                                .length
                                        } participants`}{" "}
                                    </span>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-search"
                                    >
                                        {" "}
                                    </span>
                                </div>
                                <button onClick={() => setOpenInvite(true)}>
                                    Invite User
                                </button>

                                {Object.entries(roomInfo?.members).map(
                                    (member, i) => (
                                        <div className="mc_member_div">
                                            {member[1]?.user?.avatarUrl ? (
                                                <div className="member_img_cont">
                                                    <img
                                                        src={`https://tm.vjoinlife.com/_matrix/media/r0/download/${
                                                            member[1].user.avatarUrl.split(
                                                                "//"
                                                            )[1]
                                                        }`}
                                                        className="m_img"
                                                        alt={`${member[1].name}'s avatar`} // Added alt for accessibility
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    {customAvator(
                                                        member[1].name,40,15
                                                    )}
                                                </div>
                                            )}

                                            <div className="member_name">
                                                {member[1].name}
                                            </div>
                                            {/* <button
                                                type="button"
                                                onClick={() =>
                                                    KickUser(member[0])
                                                }
                                            >
                                                Remove User
                                            </button> */}
                                            <button  onClick={() =>
                                                    KickUser(member[0])
                                                } class="btn_remove" type="button"> <i class="fa fa-trash"></i></button>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className="gim_footer_cont">
                                <div className="exit_div" onClick={LeaveRoom}>
                                    <span className="fa fa-sign-out exit_icon"></span>

                                    <span className="text"> Exit group </span>
                                </div>

                                <div className="report_div">
                                    <span className="fa fa-thumbs-down report_icon"></span>

                                    <span className="text"> Report group </span>
                                </div>
                            </div>

                            {openInvite && (
                                <InviteUser
                                    open={openInvite}
                                    setOpen={setOpenInvite}
                                    inviteUserCallBack={inviteUserCallBack}
                                    inviteUserListCallBack={
                                        inviteUserListCallBack
                                    }
                                    roomInfo={roomInfo}
                                />
                            )}
                        </>
                    )}
                    <div>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        {viewProfileSection === "upload-photo" ? (
                            <ModalReuse
                                open={modalOpen}
                                setModalOpen={setModalOpen}
                            >
                                <Box sx={style}>
                                    <div className="gim_header_top inner_box_crop_image">
                                        <span
                                            style={{ color: "white" }}
                                            className="fa fa-close close_icon"
                                            onClick={() => setModalOpen(false)}
                                        />
                                        <span style={{ color: "white" }}>
                                            {" "}
                                            Drag the image to adjust{" "}
                                        </span>
                                    </div>

                                    <EasyCrop
                                        setAvatarUrl={setAvatarUrl}
                                        getAvatarUrl={getAvatarUrl}
                                        setModalOpen={setModalOpen}
                                        setImage={setImage}
                                        imageCrop={imageCrop}
                                    />
                                </Box>
                            </ModalReuse>
                        ) : viewProfileSection === "take-photo" ? (
                            <ModalReuse
                                open={modalOpen}
                                setModalOpen={setModalOpen}
                            >
                                <Box sx={styleCamera}>
                                    <div className="gim_header_top inner_box_crop_image">
                                        <span
                                            style={{ color: "white" }}
                                            className="fa fa-close close_icon"
                                            onClick={() => setModalOpen(false)}
                                        />
                                        <span style={{ color: "white" }}>
                                            {" "}
                                            Take Photo{" "}
                                        </span>
                                    </div>

                                    <div
                                        style={{ marginTop: "50px" }}
                                        className="d-flex flex-column"
                                    >
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={videoConstraints}
                                            className="w-100"
                                            style={{ height: "30rem" }}
                                        />
                                        <div className="captureButton-profile">
                                            <button
                                                className="btn btn-primary btn-circle"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    capture();
                                                }}
                                            >
                                                <i className="fa fa-camera"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Box>
                            </ModalReuse>
                        ) : (
                            ""
                        )}

                        {/* <EasyCrop image={image} modalOpen={modalOpen} setModalOpen={setModalOpen}/> */}
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    );
}

export default ProfileEdit;
