import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRoom } from "../../../actions/desktop";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import moment from "moment";
import placeholder2 from "../../../assets/images/Frame.png";
import one from "../../../assets/images/1.png";
import two from "../../../assets/images/2.png";
import three from "../../../assets/images/3.png";
import four from "../../../assets/images/4.png";
import five from "../../../assets/images/5.png";
import six from "../../../assets/images/6.png";
import textMessage from "../../../assets/ringtones/message.mp3";
import orderTune from "../../../assets/ringtones/order.mp3";
import { Tooltip } from "@material-ui/core";
import capitalizeFirstLetter from "./ReusableComponnet/CaptizeFiestLetter";
import { customAvator } from "../../../actions/types";
const LeftPaneListItem = (props) => {
    const time = props.time;
    const room = props.room;
    let arrayImage = [one, two, placeholder2, three, four, five, six];
    let arr = [];
    console.log("LeftPaneListItem", props);
    const context = useGlobalContext();
    const [playSound, setPlaySound] = useState(false);
    const filterRoom = (id) => {
        const roomFilter = context?.getRoom.find((val) => val?.roomId == id);
        context.setGroupName(roomFilter.name);
    };
    for (let i = 0; i < room?.messages?.length; i++) {
        const message = room.messages[i];
        if (!message.isRead) {
        }
        if (message.receivedOrSent && message.receivedOrSent === "RECEIVED") {
            arr.push(message);
        }
    }

    const selectedRow = useSelector((state) => {
        return state.desktop.selectedRow;
    });

    const dispatch = useDispatch();
    let selectedRoomId = -1;

    if (selectedRow) {
        selectedRoomId = selectedRow?.roomId;
    }
    useEffect(() => {
        if (selectedRow) {
            context?.setStartCall(selectedRow?.roomId);
        }
    }, [selectedRow]);

    let unreadMessageCount = 0;
    const audioRef = useRef(null);
    const audioRefHtml = useRef(null);
    useEffect(() => {
        const handleUserInteraction = () => {
            setAutoplayAllowed(true);
        };
        console.log("handleUserInteractionss", autoplayAllowed);
        document.addEventListener("click", handleUserInteraction);

        return () => {
            document.removeEventListener("click", handleUserInteraction);
        };
    }, []);
    const [autoplayAllowed, setAutoplayAllowed] = useState(true); // Set to true initially
    const containsHTML = (str) => {
        const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>.*?<\/\1>/gi;
        return pattern.test(str);
    };
    const playAudio = () => {
        if (autoplayAllowed && context.settingTune.messageTune) {
            audioRef.current?.play().catch((error) => {
                // Handle autoplay error, if needed
                console.error("Autoplay failed:", error);
            });
        }
    };

    const playAudioHtml = () => {
        if (autoplayAllowed && context.settingTune.messageTune) {
            audioRefHtml.current?.play().catch((error) => {
                // Handle autoplay error, if needed
                console.error("Autoplay failed:", error);
            });
        }
    };

    //  useEffect(() => {
    //     const handleUserInteraction = () => {
    //         setAutoplayAllowed(true);
    //         document.removeEventListener('click', handleUserInteraction);
    //     };

    //     const playAudio = () => {
    //         if (autoplayAllowed) {
    //             audioRef.current?.play().catch(error => {
    //                 // Handle autoplay error, if needed
    //                 console.error('Autoplay failed:', error);
    //             });
    //         }
    //     };
    //     playAudio();

    //     document.addEventListener('click', handleUserInteraction);

    //     return () => {
    //         document.removeEventListener('click', handleUserInteraction);
    //     };

    // }, [autoplayAllowed]);
    useEffect(() => {
        for (let i = 0; i < arr?.length; i++) {
            if (arr[i].receivedOrSent && arr[i].receivedOrSent === "RECEIVED") {
                const toTimestamp = (strDate) => {
                    const dt = Date.parse(strDate);
                    return dt / 1000;
                };

                if (
                    (arr[i].content.msgtype === "kuumba.videocall" &&
                        context.hideRejectAccept) ||
                    (arr[i].content.msgtype === "kuumba.audiocall" &&
                        context.hideRejectAccept)
                ) {
                    let callTime = arr[i].origin_server_ts;
                    let currentTime = toTimestamp(new Date()) * 1000;
                    let diff = Number((currentTime - callTime) / 1000);
                    const time = moment(arr[i].origin_server_ts).format(
                        "DD-MM-YYYY hh:MM:ss"
                    );
                    if (diff < 20) {
                        filterRoom(arr[i]?.room_id);
                        context?.setOpenIncomingCall(true);

                        context?.setOpen(false);
                        context?.setIncomingCall(arr[i].room_id);
                    }
                } else if (arr[i].content.msgtype === "m.text") {
                    //console.log("abccc",arr[i].content.body)
                    let callTime = arr[i].origin_server_ts;
                    let currentTime = toTimestamp(new Date()) * 1000;
                    let diff = Number((currentTime - callTime) / 1000);
                    const time = moment(arr[i].origin_server_ts).format(
                        "DD-MM-YYYY hh:MM:ss"
                    );
                    if (diff < 1) {
                        context?.notifyMe();
                        if (containsHTML(arr[i].content.body)) {
                            console.log("abccc", arr[i].content.body);

                            // setPlaySound(true)
                            playAudioHtml();
                        } else {
                            // setPlaySound(false)
                            playAudio();
                        }
                        //setAutoplayAllowed(true)
                    }
                } else {
                }
                if (
                    arr[i].isRead === false &&
                    arr[i].content.msgtype !== "m.reaction"
                ) {
                    unreadMessageCount++;

                    console.log("unread", arr[i]);
                    context?.setOpen(true);
                    context?.setCount(unreadMessageCount);
                }
            }
        }
    }, [arr, context]);

    const handleSelectedRoomId = useCallback(() => {
        dispatch(selectRoom(room));
        context.setNewClose(false);
        context.setSelectedRoom(room);
        context?.setGoBottom(true);

        context.setBottom(true);
        if (context.adjustChat) {
            context.setAdjustLeft(true);
        }
    }, [room]);

    return (
        <div id={room?.roomId}>
            {/* <Tooltip
                placement="bottom"
                arrow
                title={
                    room?.lastMessageReceived?.content?.msgtype !==
                    undefined ? (
                        <div style={{ display: "flex" }}>
                           
                            <p>
                                {room?.lastMessageReceived?.content?.body
                                    ? room?.lastMessageReceived?.content?.body
                                    : `${
                                          room?.lastMessageReceived?.sender ==
                                          localStorage.getItem("mxvr_user_id")
                                              ? "You"
                                              : room?.lastMessageReceived
                                                    ?.sender_name
                                      } 
                                                                                      reacted ${
                                                                                          room
                                                                                              ?.lastMessageReceived
                                                                                              ?.content?.[
                                                                                              "m.relates_to"
                                                                                          ]
                                                                                              ?.key
                                                                                      } to:"${
                                          room?.lastMessageReceived
                                              ?.originalEvent?.content?.body
                                              ? room?.lastMessageReceived
                                                    ?.originalEvent?.content
                                                    ?.body
                                              : room?.lastMessageReceived
                                                    ?.originalEvent?.event
                                                    ?.content?.body
                                      }"`}
                            </p>
                        </div>
                    ) : (
                        "No Message "
                    )
                }
            >
                         </Tooltip> */}

            <li
                key={room.roomId}
                onClick={
                    handleSelectedRoomId
                    // alert("selected")
                    //// console.log("ahmadgee",selectedRow.roomId);
                    //   context.setStartCall(room?.roomId);
                }
                className={`${
                    room.roomId == selectedRoomId ? "active" : null
                } ${unreadMessageCount !== 0 ? "unread_msgs_contact" : null}`}
                // className={room.roomId == selectedRoomId ? "active" : null}
            >
                <div className="d-flex">
                <div className="d-flex group_text">
               
                    {room?.avatarUrl ? (
                        <div className="img-sec me-3">
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={room?.avatarUrl }
                                className="rounded-circle"
                                alt="Avatar"
                            />
                        </div>
                    ) : (
                        <div>   
                        
                        { customAvator(room.name,40,15)}
                        </div>
                        
                    )}
                   
                    <div className="names-message group_text">
                        <label className="group_text">
                            {capitalizeFirstLetter(room.name)}
                        </label>

                        {room?.myUserId !== room?.member?.userId ? (
                            <div style={{ marginTop: "2px" }} className="m-0">
                                {room && room.isTyping ? (
                                    <p className="msg_typing">
                                        {room.member.name} ...
                                    </p>
                                ) : (
                                    <>
                                        {room?.lastMessageReceived?.content
                                            ?.msgtype !== undefined ? (
                                            <div style={{ display: "flex" }}>
                                                {/* <p>
                                                    {room?.lastMessageReceived
                                                        ?.isRead ? (
                                                        <BsCheckAll className="msg_read" />
                                                    ) : (
                                                        <BsCheck className="msg_sent" />
                                                    )}
                                                </p> */}
                                                <p className="last_msg_text">
                                                    {room?.lastMessageReceived
                                                        ?.content?.body
                                                        ? `
                                                       ${
                                                           room
                                                               ?.lastMessageReceived
                                                               ?.sender ==
                                                           localStorage.getItem(
                                                               "mxvr_user_id"
                                                           )
                                                               ? "You: "
                                                               : room
                                                                     ?.lastMessageReceived
                                                                     ?.sender_name +
                                                                 ": "
                                                       } 
                                                       
                                                       ${
                                                           room
                                                               ?.lastMessageReceived
                                                               ?.content?.body
                                                       }
                                                              `
                                                        : `${
                                                              room
                                                                  ?.lastMessageReceived
                                                                  ?.sender ==
                                                              localStorage.getItem(
                                                                  "mxvr_user_id"
                                                              )
                                                                  ? "You: "
                                                                  : room
                                                                        ?.lastMessageReceived
                                                                        ?.sender_name +
                                                                    ": "
                                                          } 
                                                                      reacted ${
                                                                          room
                                                                              ?.lastMessageReceived
                                                                              ?.content?.[
                                                                              "m.relates_to"
                                                                          ]?.key
                                                                      } to:"${
                                                              room
                                                                  ?.lastMessageReceived
                                                                  ?.originalEvent
                                                                  ?.content
                                                                  ?.body
                                                                  ? room
                                                                        ?.lastMessageReceived
                                                                        ?.originalEvent
                                                                        ?.content
                                                                        ?.body
                                                                  : room
                                                                        ?.lastMessageReceived
                                                                        ?.originalEvent
                                                                        ?.event
                                                                        ?.content
                                                                        ?.body
                                                          }"`}
                                                </p>
                                            </div>
                                        ) : room?.lastMessageReceived?.type ===
                                          "m.room.member" ? (
                                            <div style={{ display: "flex" }}>
                                                <p className="last_msg_text">
                                                    {room?.lastMessageReceived
                                                        ?.content?.membership &&
                                                        `${
                                                            room
                                                                ?.lastMessageReceived
                                                                ?.sender ==
                                                            localStorage.getItem(
                                                                "mxvr_user_id"
                                                            )
                                                                ? "You: "
                                                                : room
                                                                      ?.lastMessageReceived
                                                                      ?.sender_name +
                                                                  ": "
                                                        } 
                                                        ${
                                                            room
                                                                ?.lastMessageReceived
                                                                ?.content
                                                                ?.membership ===
                                                            "join"
                                                                ? "Joined"
                                                                : room
                                                                      ?.lastMessageReceived
                                                                      ?.content
                                                                      ?.membership
                                                        }
                                                                     ${
                                                                         room
                                                                             ?.lastMessageReceived
                                                                             ?.content
                                                                             ?.displayname ===
                                                                         room
                                                                             ?.lastMessageReceived
                                                                             ?.sender_name
                                                                             ? ""
                                                                             : room
                                                                                   ?.lastMessageReceived
                                                                                   ?.content
                                                                                   ?.displayname
                                                                     }`}
                                                </p>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                <p className="last_msg_text">
                                                    {room?.lastMessageReceived
                                                        ?.content?.creator &&
                                                        `${
                                                            room
                                                                ?.lastMessageReceived
                                                                ?.content
                                                                .creator ==
                                                            localStorage.getItem(
                                                                "mxvr_user_id"
                                                            )
                                                                ? "You: "
                                                                : room
                                                                      ?.lastMessageReceived
                                                                      ?.sender_name +
                                                                  ": "
                                                        } 
                                                                        created group "
                                                                     ${
                                                                         room?.name
                                                                     }"`}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <>
                                {room?.lastMessageReceived ? (
                                    <div style={{ display: "flex" }}>
                                        <p className="last_msg_text">
                                            {room?.lastMessageReceived?.content
                                                ?.body &&
                                                `
                                                       ${
                                                           room
                                                               ?.lastMessageReceived
                                                               ?.sender ==
                                                           localStorage.getItem(
                                                               "mxvr_user_id"
                                                           )
                                                               ? "You"
                                                               : room
                                                                     ?.lastMessageReceived
                                                                     ?.sender_name
                                                       } 
                                                       
                                                       ${
                                                           room
                                                               ?.lastMessageReceived
                                                               ?.content?.body
                                                       }
                                                              `}

                                            {props?.lastMessage?.reaction
                                                ? `${
                                                      props?.lastMessage
                                                          ?.reactmsg?.reactions[
                                                          props?.lastMessage
                                                              ?.reactmsg
                                                              ?.reactions
                                                              .length - 1
                                                      ].sender ==
                                                      localStorage.getItem(
                                                          "mxvr_user_id"
                                                      )
                                                          ? "You: "
                                                          : props?.lastMessage
                                                                ?.reactmsg
                                                                ?.reactions[
                                                                props
                                                                    ?.lastMessage
                                                                    ?.reactmsg
                                                                    ?.reactions
                                                                    .length - 1
                                                            ].sender_name + ": "
                                                  } reacted ${
                                                      props?.lastMessage
                                                          ?.reaction
                                                  } to:"${
                                                      props?.lastMessage
                                                          ?.reactmsg?.content
                                                          ?.body
                                                  }"`
                                                : props?.lastMessage?.msgBody}
                                        </p>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                    </div>
                    <div className="time ms-auto small">
                        <p className="msg_time">{time}</p>

                        {unreadMessageCount > 0 &&
                            !props?.lastMessage?.reactmsg?.reactions && (
                                <div className="msg_count_div">
                                    <p className="msg_count">
                                        {unreadMessageCount}
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            </li>
            <audio ref={audioRef} muted={!autoplayAllowed}>
                <source src={textMessage} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio>
            <audio ref={audioRefHtml} muted={!autoplayAllowed}>
                <source src={orderTune} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio>
        </div>
    );
};

export default React.memo(LeftPaneListItem);
